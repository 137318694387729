import {Col} from "react-bootstrap";
import "animate.css"; // 确保引入动画样式

export const Video = () => {
  return (
    <Col lg={12}>
      <div className="video-bx wow slideInUp">
        <br />
        <h3>澳亚基督教会简介（华语）</h3>
        <br />
        <div className="video-wrapper">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/S0sBdA8hzkU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Col>
  );
};
