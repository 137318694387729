import React from "react";
import colorSharp from "../assets/img/color-sharp.png"
import "./Requirements.css"; // 导入 CSS 文件

export const Requirements = () => {
  return (
    <section className="requirements" id="requirements">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="requirements-bx wow zoomIn">
              <h2>领诗要求</h2>
              <blockquote>
                <p>
                  我的心哪！你要称颂耶和华！凡在我里面的，也要称颂祂的圣名!
                  <br />
                  （诗篇 103：1）
                </p>
              </blockquote>
              <p>这里是领诗要求的介绍内容。</p>
              <ul>
                <li>领诗者应为受洗的基督徒。</li>
                <li>领诗者必须提前准备，避免临场挑选诗歌。</li>
                <li>
                  诗歌的选择建议尽量配合查经或讨论的主题；数量不超过三首。
                </li>
                <li>
                  领诗者应在当周团契前的星期二选取好诗歌，并发送到提摩太敬拜群。如果没有问题，于星期三发送到提摩太大群，让团员熟悉这些诗歌。
                </li>
              </ul>
              <p>如果你想加入诗歌带领服侍，请与导师联系。</p>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  );
};


