import {Container, Row, Col, Tab, Nav} from "react-bootstrap";
import {ProjectCard} from "./CouncilCard";
import projImg1 from "../assets/img/project-img1.jpg";
import projImg2 from "../assets/img/project-img2.jpg";
import projImg3 from "../assets/img/project-img3.jpg";
import projImg4 from "../assets/img/project-img4.jpg";
import projImg5 from "../assets/img/project-img5.jpg";
import projImg6 from "../assets/img/project-img6.jpg";
import projImg7 from "../assets/img/project-img7.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";
import "./Council.css"; // Import the CSS file

export const Council = () => {
  const projects = [
    {
      title: "导师",
      description: "指导与支持",
      imgUrl: projImg1,
    },
    {
      title: "正副团长",
      description: "带领团契",
      imgUrl: projImg2,
    },
    {
      title: "其他职员",
      description: "总务、文书及关怀",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="Council">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({isVisible}) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>职员会</h2>
                  <p>以下是职员会人员的介绍及其职责</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">导师</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">正副团长</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">其他职员</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row className="project-details justify-content-center align-items-center">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard {...projects[0]} />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">导师 – RINA</span>
                                <br />
                                <br />
                                协助教牧牧养团友；
                                <br />
                                栽培团契领袖；
                                <br />
                                训练查经组长；
                                <br />
                                引导团友追求灵命成长
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row className="project-details justify-content-center align-items-center mb-4">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard
                              title="团长"
                              description="带领团契"
                              imgUrl={projImg2}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">
                                  团长 – MONNA
                                </span>
                                <br />
                                <br />
                                定期向牧者汇报团契的情况；
                                <br />
                                协助导师的工作；
                                <br />
                                安排查经；
                                <br />
                                协调查经组长之间的关系
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="project-details justify-content-center align-items-center mb-4">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard
                              title="副团长"
                              description="带领团契"
                              imgUrl={projImg3}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">
                                  副团长 – ASHLEY
                                </span>
                                <br />
                                <br />
                                配合导师和团长组织安排团契的计划；
                                <br />
                                团契内部的沟通；
                                <br />
                                团契外部的联络。
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row className="project-details justify-content-center align-items-center mb-4">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard
                              title="总务"
                              description="管理团契事务"
                              imgUrl={projImg4}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">
                                  总务 – DANIEL
                                </span>
                                <br />
                                <br />
                                清理聚会后的场地；
                                <br />
                                保存、购买及适当地使用团契所需物品。
                                <br />
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="project-details justify-content-center align-items-center mb-4">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard
                              title="文书"
                              description="记录和文件管理"
                              imgUrl={projImg5}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">
                                  文书 – GRACE
                                </span>
                                <br />
                                <br />
                                保存团契的书信往来文件；
                                <br />
                                记录团契职员会议；
                                <br />
                                每三个月一次向团契报告聚会与活动情况。
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="project-details justify-content-center align-items-center mb-4">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard
                              title="关怀"
                              description="关怀和支持"
                              imgUrl={projImg6}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">关怀 – JACK</span>
                                <br />
                                <br />
                                关心并照顾团友
                                <br />
                                关顾团友灵命的成长；
                                <br />
                                按需要作访谈或探访。
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="project-details justify-content-center align-items-center mb-4">
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <ProjectCard
                              title="关怀"
                              description="关怀和支持"
                              imgUrl={projImg7}
                            />
                          </Col>
                          <Col
                            xs={12}
                            md={6}
                            lg={4}
                            className="d-flex justify-content-center"
                          >
                            <div className="project-description">
                              <p>
                                <span className="mentor-name">
                                  关怀 – YUMENG
                                </span>
                                <br />
                                <br />
                                关心并照顾团友；
                                <br />
                                关顾团友灵命的成长；
                                <br />
                                按需要作访谈或探访。
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="Background"
      />
    </section>
  );
};
