import React from "react";
import colorSharp from "../assets/img/color-sharp.png";
import "./Regulations.css"; // 导入 CSS 文件

export const Regulations = () => {
  return (
    <section className="regulations" id="regulations">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="regulations-bx wow zoomIn">
              <h2>团契规章</h2>
              <p>
                提摩太团契是一个有灵命增长、有爱心关怀和有福音负担的团契，是对外开放的团契，我们期望并欢迎常有新人加入。为了更好地实现这些目标，以下是我们的团契规则：
              </p>
              <ul>
                <li>
                  团契查经：团契每周六举行圣经查经或主题性的分享，主题分享穿插与青年人
                  紧密相关的成长、生活方面的信息。查经小组长负责提前准备经文和讨论题目，带领团员进行分享和交流。团员应当在查经时认真思考，积极参与分享和讨论。
                </li>
                <li>
                  团契流程：
                  <table>
                    <thead>
                      <tr>
                        <th>内容</th>
                        <th>时间安排</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>破冰游戏</td>
                        <td>15 分钟</td>
                      </tr>
                      <tr>
                        <td>家事报告</td>
                        <td>5 分钟</td>
                      </tr>
                      <tr>
                        <td>诗歌敬拜</td>
                        <td>15 分钟</td>
                      </tr>
                      <tr>
                        <td>查经或主题分享</td>
                        <td>90 分钟</td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  准时出席：周六团契开始时间为下午 4 点 30
                  分，当周参与事奉的人员请于下午 4 点 50
                  分到达并预备，团契将于下午 5 点整准时开始。
                </li>
                <li>
                  参与及事奉：团员应积极承担分配的事奉职责，如有事无法参加，应提前主动和团契职员协商。我们鼓励团员之间相互关心、支持和鼓励，共同建立一个充满爱心和关怀的大家庭。
                </li>
                <li>
                  膳食准备：通常会安排三位团员一同准备当周膳食。团员应根据当周报名人数进行采购、预备、烹饪（预算建议控制在\$10
                  x
                  参加团契人数内）。建议团员带来预先烹饪好的食物，以确保能参加当周的查经或分享活动。
                </li>
                <li>
                  后勤清洁：团员分组每月轮流负责清理用过的用餐区域，包括餐桌和地面上的垃圾，并清洗使用过的餐具，擦干净后归还原处。
                </li>
                <li>
                  分享：提摩太致力于为团员创造一个安全的空间，在这里大家可以自由分享。团员的分享必须保密，不可以四处流传，以免造成伤害。
                </li>
                <li>
                  不同意见：提摩太鼓励成员分享自己的想法，但也需尊重他人。讨论时应保持理性，不允许使用攻击性的言辞，避免发生争执和冲突。
                </li>
                <li>
                  提摩太使用的交流平台：群主由导师，团长和副团长担任，负责管理群聊发布信息。新成员需要定期参加团契和主日后才能加入平台。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  );
};
