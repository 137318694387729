// import {useState, useEffect} from "react";
// import {Navbar, Nav, Container, Offcanvas} from "react-bootstrap";
// import logo from "../assets/img/logo.png";
// import {HashLink as Link} from "react-router-hash-link";
// import {BrowserRouter as Router} from "react-router-dom";
// import "./NavBar.css"; // 确保引用了正确的CSS文件

// export const NavBar = () => {
//   const [activeLink, setActiveLink] = useState("home");
//   const [scrolled, setScrolled] = useState(false);
//   const [showOffcanvas, setShowOffcanvas] = useState(false);

//   useEffect(() => {
//     const onScroll = () => {
//       if (window.scrollY > 50) {
//         setScrolled(true);
//       } else {
//         setScrolled(false);
//       }
//     };

//     window.addEventListener("scroll", onScroll);

//     return () => window.removeEventListener("scroll", onScroll);
//   }, []);

//   const onUpdateActiveLink = (value) => {
//     setActiveLink(value);
//     setShowOffcanvas(false); // 关闭 Offcanvas
//   };

//   const logoStyle = {
//     width: "100px", // 固定宽度
//     height: "auto", // 根据宽度自动调整高度
//   };

//   return (
//     <Router>
//       <div id="top-spacer1" style={{height: "50px"}}></div>
//       <div id="top-spacer2" style={{height: "50px"}}></div>
//       <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
//         <Container>
//           <Navbar.Brand href="/">
//             <img src={logo} alt="Logo" style={logoStyle} />
//           </Navbar.Brand>
//           <Navbar.Toggle
//             aria-controls="offcanvasNavbar"
//             onClick={() => setShowOffcanvas(!showOffcanvas)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </Navbar.Toggle>
//           <Navbar.Offcanvas
//             id="offcanvasNavbar"
//             aria-labelledby="offcanvasNavbarLabel"
//             placement="end"
//             show={showOffcanvas}
//             onHide={() => setShowOffcanvas(false)}
//             className="bg-dark text-white"
//           >
//             <Offcanvas.Body>
//               <Nav className="ms-auto align-items-center">
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#home"
//                   className={
//                     activeLink === "home" ? "active navbar-link" : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("home")}
//                 >
//                   主页
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#Aboutus"
//                   className={
//                     activeLink === "Aboutus"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("Aboutus")}
//                 >
//                   关于我们
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#Council"
//                   className={
//                     activeLink === "Council"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("Council")}
//                 >
//                   职员会
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#regulations"
//                   className={
//                     activeLink === "regulations"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("regulations")}
//                 >
//                   团契规章
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#requirements"
//                   className={
//                     activeLink === "requirements"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("requirements")}
//                 >
//                   领诗要求
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#activities"
//                   className={
//                     activeLink === "activities"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("activities")}
//                 >
//                   过往活动
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#connect"
//                   className={
//                     activeLink === "connect"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("connect")}
//                 >
//                   联络我们
//                 </Nav.Link>
//               </Nav>
//             </Offcanvas.Body>
//           </Navbar.Offcanvas>
//         </Container>
//       </Navbar>
//     </Router>
//   );
// };




// import {useState, useEffect} from "react";
// import {Navbar, Nav, Container, Offcanvas} from "react-bootstrap";
// import logo from "../assets/img/logo.png";
// import extraLogo from "../assets/img/extraLogo.jpg"; // 引用额外的 logo
// import {HashLink as Link} from "react-router-hash-link";
// import {BrowserRouter as Router} from "react-router-dom";
// import "./NavBar.css"; // 确保引用了正确的CSS文件

// export const NavBar = () => {
//   const [activeLink, setActiveLink] = useState("home");
//   const [scrolled, setScrolled] = useState(false);
//   const [showOffcanvas, setShowOffcanvas] = useState(false);

//   useEffect(() => {
//     const onScroll = () => {
//       if (window.scrollY > 50) {
//         setScrolled(true);
//       } else {
//         setScrolled(false);
//       }
//     };

//     window.addEventListener("scroll", onScroll);

//     return () => window.removeEventListener("scroll", onScroll);
//   }, []);

//   const onUpdateActiveLink = (value) => {
//     setActiveLink(value);
//     setShowOffcanvas(false); // 关闭 Offcanvas
//   };

//   const logoStyle = {
//     height: "60px", // 固定高度
//     width: "auto", // 根据高度自动调整宽度
//     marginRight: "10px", // 增加间距
//   };

//   return (
//     <Router>
//       <div id="top-spacer1" style={{height: "50px"}}></div>
//       <div id="top-spacer2" style={{height: "50px"}}></div>
//       <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
//         <Container>
//           <Navbar.Brand href="/">
//             <img src={logo} alt="Logo" style={logoStyle} />
//             <img src={extraLogo} alt="Extra Logo" style={logoStyle} />
//             {/* 添加额外的 logo */}
//           </Navbar.Brand>
//           <Navbar.Toggle
//             aria-controls="offcanvasNavbar"
//             onClick={() => setShowOffcanvas(!showOffcanvas)}
//           >
//             <span className="navbar-toggler-icon"></span>
//           </Navbar.Toggle>
//           <Navbar.Offcanvas
//             id="offcanvasNavbar"
//             aria-labelledby="offcanvasNavbarLabel"
//             placement="end"
//             show={showOffcanvas}
//             onHide={() => setShowOffcanvas(false)}
//             className="bg-dark text-white"
//           >
//             <Offcanvas.Body>
//               <Nav className="ms-auto align-items-center">
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#home"
//                   className={
//                     activeLink === "home" ? "active navbar-link" : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("home")}
//                 >
//                   主页
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#Aboutus"
//                   className={
//                     activeLink === "Aboutus"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("Aboutus")}
//                 >
//                   关于我们
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#Council"
//                   className={
//                     activeLink === "Council"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("Council")}
//                 >
//                   职员会
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#regulations"
//                   className={
//                     activeLink === "regulations"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("regulations")}
//                 >
//                   团契规章
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#requirements"
//                   className={
//                     activeLink === "requirements"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("requirements")}
//                 >
//                   领诗要求
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#activities"
//                   className={
//                     activeLink === "activities"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("activities")}
//                 >
//                   过往活动
//                 </Nav.Link>
//                 <Nav.Link
//                   as={Link}
//                   smooth
//                   to="/#connect"
//                   className={
//                     activeLink === "connect"
//                       ? "active navbar-link"
//                       : "navbar-link"
//                   }
//                   onClick={() => onUpdateActiveLink("connect")}
//                 >
//                   联络我们
//                 </Nav.Link>
//               </Nav>
//             </Offcanvas.Body>
//           </Navbar.Offcanvas>
//         </Container>
//       </Navbar>
//     </Router>
//   );
// };
import {useState, useEffect} from "react";
import {Navbar, Nav, Container, Offcanvas} from "react-bootstrap";
import logo from "../assets/img/logo.png";
import extraLogo from "../assets/img/extraLogo.jpg"; // 引用额外的 logo
import {HashLink as Link} from "react-router-hash-link";
import {BrowserRouter as Router} from "react-router-dom";
import "./NavBar.css"; // 确保引用了正确的CSS文件

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    setShowOffcanvas(false); // 关闭 Offcanvas
  };

  const logoStyle = {
    height: "60px", // 固定高度
    width: "auto", // 根据高度自动调整宽度
    marginRight: "10px", // 增加间距
  };

  return (
    <Router>
      <div id="top-spacer1" style={{height: "50px"}}></div>
      <div id="top-spacer2" style={{height: "50px"}}></div>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" style={logoStyle} />
            <img src={extraLogo} alt="Extra Logo" style={logoStyle} />
            {/* 添加额外的 logo */}
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            className="bg-dark text-white"
          >
            <Offcanvas.Body>
              <Nav className="ms-auto align-items-center">
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#home"
                  className={
                    activeLink === "home" ? "active navbar-link" : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("home")}
                >
                  主页
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#Aboutus"
                  className={
                    activeLink === "Aboutus"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("Aboutus")}
                >
                  关于我们
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#Council"
                  className={
                    activeLink === "Council"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("Council")}
                >
                  职员会
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#regulations"
                  className={
                    activeLink === "regulations"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("regulations")}
                >
                  团契规章
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#requirements"
                  className={
                    activeLink === "requirements"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("requirements")}
                >
                  领诗要求
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#activities"
                  className={
                    activeLink === "activities"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("activities")}
                >
                  过往活动
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#baptism" // 新增导航链接
                  className={
                    activeLink === "baptism"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("baptism")}
                >
                  洗礼纪念
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  smooth
                  to="/#connect"
                  className={
                    activeLink === "connect"
                      ? "active navbar-link"
                      : "navbar-link"
                  }
                  onClick={() => onUpdateActiveLink("connect")}
                >
                  联络我们
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </Router>
  );
};
