import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import img1 from "../assets/img/image1.png"; 
import img2 from "../assets/img/image2.png"; 
import img3 from "../assets/img/image3.png"; 
import img4 from "../assets/img/image4.png"; 
import "./Activities.css"; // 导入 CSS 文件

export const Activities = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 1,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  const items = [
    {img: img1, title: "Point Turton"},
    {img: img2, title: "Hot Pot Night"},
    {img: img3, title: "Flaherty’s Beach"},
    {img: img4, title: "Deep Creek National Park"},
  ];

  return (
    <section className="activities" id="activities">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="activities-bx wow zoomIn">
              <h2>过往活动</h2>

              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true} // 启用自动播放
                autoPlaySpeed={3000} // 自动播放速度，单位为毫秒
                className="owl-carousel owl-theme activities-slider"
              >
                {items.map((item, index) => (
                  <div key={index} className="item">
                    <img src={item.img} alt={item.title} />
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  );
};
