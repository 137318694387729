import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import img1 from "../assets/img/baptism1.jpg"; // 修改图片引用
import img2 from "../assets/img/baptism2.jpg"; // 修改图片引用
import img3 from "../assets/img/baptism3.jpg"; // 修改图片引用
import img4 from "../assets/img/baptism4.jpg"; // 修改图片引用
import "./Baptism.css"; // 导入新的 CSS 文件

export const Baptism = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 1,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 1,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 1,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  const items = [
    {img: img1, title: "Church Baptism"},
    {img: img2, title: "Church Baptism"},
    {img: img3, title: "Church Baptism"},
    {img: img4, title: "Church Baptism"},
  ];

  return (
    <section className="baptism" id="baptism">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="baptism-bx wow zoomIn">
              <h2>洗礼纪念</h2>

              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true} // 启用自动播放
                autoPlaySpeed={3000} // 自动播放速度，单位为毫秒
                className="owl-carousel owl-theme baptism-slider"
              >
                {items.map((item, index) => (
                  <div key={index} className="item">
                    <img src={item.img} alt={item.title} />
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  );
};
