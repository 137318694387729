import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {NavBar} from "./components/NavBar";
import {Banner} from "./components/Banner";
import {Aboutus} from "./components/Aboutus";
import {Council} from "./components/Council";
import {Regulations} from "./components/Regulations";
import {Requirements} from "./components/Requirements";
import {Activities} from "./components/Activities";
import {Baptism} from "./components/Baptism"; // 引入Baptism组件
import {Contact} from "./components/Contact";
import {Footer} from "./components/Footer";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Aboutus />
      <Council />
      <Regulations />
      <Requirements />
      <Activities />
      <Baptism /> {/* 添加Baptism组件 */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
