import {Container, Row, Col} from "react-bootstrap";
import {MailchimpForm} from "./MailchimpForm";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} className="text-center footer-links-container">
            <div className="footer-links">
              <a href="https://www.aaccsa.org.au/" className="footer-link">
                <br></br>教会官方网站
              </a>
              <a
                href="https://www.youtube.com/@austral-asianchristianchur3958"
                className="footer-link"
              >
                <br></br>教会官方YouTube
              </a>
            </div>
            <p className="footer-copyright">
              Copyright 2024. 澳亚基督教会Austral-Asian Christian Church
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
