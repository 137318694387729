import React from "react";
import meter from "../assets/img/meter.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import "./Aboutus.css"; // 确保引用了正确的CSS文件

export const Aboutus = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  return (
    <section className="about-us" id="Aboutus">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="about-us-bx wow zoomIn">
              <h2>关于我们</h2>
              <p>
                提摩太团契这个名字出自提摩太前书4章12节：不可叫人小看你年轻，总要在言语、行为、爱心、信心、清洁上，都作信徒的榜样。
                <br />
                <br />
                本团契是由一群来自海外的澳洲留学生和已经在澳洲定居的青年人组成的团契，主要对象是说华语的初入职场的青年和在读大学生和中学生。
                <br />
                聚会时间：每星期六晚上 5 点整。
                <br />
                内容：小组查经、特别分享、康乐活动等。
                <br />
                在团契中，我们一起学习神的话语，在主的爱中相互关爱、鼓励和建立，同时在生活中分享经历，共同在基督里成长。
              </p><br></br>

              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                arrows={false}
                className="owl-carousel owl-theme aboutus-slider"
              >
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="love" />
                    <div className="circle-text">仁爱</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="joy" />
                    <div className="circle-text">喜乐</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="peace" />
                    <div className="circle-text">和平</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="patience" />
                    <div className="circle-text">忍耐</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="kindness" />
                    <div className="circle-text">恩慈</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="googness" />
                    <div className="circle-text">良善</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="faithfulness" />
                    <div className="circle-text">信实</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="gentleness" />
                    <div className="circle-text">温柔</div>
                  </div>
                </div>
                <div className="item">
                  <div className="circle">
                    <img src={meter} alt="self-control" />
                    <div className="circle-text">节制</div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="backgroud" />
    </section>
  );
};
